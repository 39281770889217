<template>
  <div style="padding: 30px 0px; text-align: center"></div>
</template>
<script>
import { accessToken } from "../utils/apis/apis";
export default {
  name: "wxLogin",
  data() {
    return {};
  },
  created() {
      console.log(window.location.href)
    const params = {
      code: window.location.href.slice(
        window.location.href.length - 1,
        window.location.href.length
      ),
    };
    this.$reqGet(accessToken.getWechatUrl1, params).then((res) => {
      if (res.data.code === 200) {
        window.location.replace(res.data.data);
      }
    });
  },
};
</script>